import logo from './logo.svg';
import mainText from './mainText.svg'
import appImages from './appImages.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <main className='pllng__main'>
        <img src={mainText} className="pllng__main-logo" alt="logo" />
        <img src={appImages} className="pllng__main-images" alt="logo" />
        <p className='pllng__main-p'>
          Pallang is a tool for those who want to use proven methods as support in their athletic development as an individual, both proactively and reactively
          <br/><br/>
          Our app is based on recognized cognitive behavioral therapy methods and is currently adapted for soccer players, but with plans to reach other fields in the future
        </p>
        <div>
          <p style={{fontWeight: 700}}>
            Want to become a Beta-Tester?
          </p>
          <p style={{marginTop: -20}}>
            Send us an email!
            <br/>
            <span style={{fontWeight: 700}}>hello@pallang.net</span>
          </p>
        </div>
        <p className='pllng__main-p' style={{fontSize: "1rem", fontWeight: 700, marginTop: 150}}>
        *Combining cognitive therapy and behavior techniques with athlete performance coaching integrated with in a digital format and AI, offers athletes a comprehensive and personalized support system to optimize their performance and achieve their goals.
        <br/><br/>
        Pallang is a initiative for health and life quality. Pallang is a Swedish based initiative for health and life quality.
        </p>
      </main>
    </div>
  );
}

export default App;
